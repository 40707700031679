.App{
    background-color: #36454F;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.navbar a{
    color: white;
    font-size: 20px;
}

.navbar-start li{
    margin-bottom: 20px;
}

.tech-card{
    background-color: rgba(100, 108, 130, 0.885);
}